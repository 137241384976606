.btn {
    padding: 6px 20px ;
}
.btn {
    text-decoration: none;
    color: white;
}
.sortby {
    border-radius: 15.5px !important;
    height: 31px !important;
}
