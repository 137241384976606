.ph-header-main__breadcrumbs {
  background-color: #fff;
}

.container h1 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.support-tile {
  background: #fff;
}
.support-heading-block {
  padding-top: 30px;
  padding-bottom: 30px;
  /* width: 70%; */
  height: 100%;
  background: #e4e4e4;
  padding-left: 20px;
  cursor: pointer;
}
.support-image {
  padding-top: 0px;
  /* padding-bottom: 30px; */
  /* width: 30%; */
  height: 100%;
  padding-left: 0px;
  cursor: pointer;
  background: white;
  border: 1px solid #dddddd;
}
.tileHeading {
  font-size: 18px;
  font-weight: 700;
  color: #353535;
}

.assetsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #ccc; */
}
@media (min-width: 1024px) {
  .col-md-4 {
    margin-top: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .support-tile {
    margin-bottom: 5px;
  }
  .support-heading-block {
    width: 50%;
    padding-left: 10px;
  }
  .support-heading-block p {
    font-size: 14px;
  }
}
@media (min-width: 375px) and (max-width: 768px) {
  .support-tile {
    margin-bottom: 5px;
  }
}
