
/* a {
  color: #000000!important;
} */

/* .nav-link{
  color: #000000 !important;
} */
.myparkersearch-tabs.nav-tabs .nav-item .nav-link.active {
  border-bottom: 1px solid #fff;
  border-top: solid 4px #ffb91d!important;
  /* background-color: #ffb91d; */
    content: "";
    display: block;
    /* height: 6px; */
    /* margin-bottom: .5px;
    margin-top: .75rem; */
    width: 100%;
    /* border: 1px solid #ffb91d; */
    /* border-bottom: none; */
    /* bottom: -1px;
    margin: -6px 0 0; */
    position: relative;
}

.ph-search-nav-tabs{
  position: relative;
  right: 0;
  left: 0;
}

.myparkersearch-tabs.nav-tabs .nav-item .nav-link:active{
  background-color: none!important;
}

.greyBackground {
  background-color: #f6f6f5;
  margin-top: -20px;
  height: 80px;
}

.ph-header-main__title .p-title{
  color: #000000;
    /* font-family: Roboto; */
    font-size: 33.18px;
    letter-spacing: 0;
    line-height: 49.76px;
    margin-top: 16px;
}
.ph-browse-list-view__right .entry i {
  color: #000000!important;
  font-size: 16px;
  margin-left: 4px;
  margin-left: 0;
        position: absolute;
        right: 6px;
        top: 50%;
}

.ph-search-nav-tabs {
  /* flex-wrap: nowrap; */
  width: 100%;
}

.ph-search-nav-tabs .nav-item .nav-link {
  line-height: 1.25;
}
.myparkersearch-tabs.nav-tabs .nav-link.active::before {
  background-color: rgb(255, 185, 29);
  /* content: "";
  display: block; */
  /* height: 6px; */
  /* width: 100%; */
}

.myparkersearch-tabs.nav-tabs {
  border-bottom: 3px solid #bcbcbc;
}
.myparkersearch-tabs.nav-tabs .nav-link {
  border-radius: unset !important;
}

.myparkersearch-tabs.nav-tabs .nav-item {
  margin: 0 .5rem;
  margin: 0 .5rem;
  position: relative;
  left: -6.8rem;
}
.myparkersearch-tabs.nav-tabs .nav-item.active:before {
  border-color: #bcbcbc;
  top: unset !important;
}
.myparkersearch-tabs.nav-tabs .nav-item.active:before {
  /* background-color: #ffb91d; */
  content: "";
  display: block;
  height: 6px;
  margin-bottom: .5px;
  margin-top: .75rem;
  width: 100%;
  border: 1px solid #ffb91d;
  border-bottom: none;
  bottom: -1px;
  margin: -6px 0 0;
  position: relative;
  top: 100%;
}

  .myparkersearch-tabs .nav-link{color: #000;}

  .myparkersearch-tabs.nav-tabs .nav-item .nav-link {
    border: 1px solid #bcbcbc;
    font-size: 1.125rem;
    margin-bottom: -4px;
    padding: .75rem 2rem .625rem;
    background: #ffffff;
}

.myparkersearch-tabs.nav-tabs .nav-item.active .nav-link {
  border-bottom-color: #fff;
}

.myparkersearch-tabs.nav-tabs {
  margin: 1rem 0 3.125rem;
  padding: 0;
}
.ph-search-contents__results{
  padding: 16px;
 width: 100%;
}

  .ph-browse-list-view__content {
    display: flex;
    justify-content: space-between;
}

.results hr {
  border: 1px solid #979797;
  border-width: 1px;
  margin: 0;
}


.support-list .ph-browse-list-view__left--img-container img {
  height: 72px!important;
  left: 1.5rem!important;
  max-width: none;
  position: relative;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.ph-browse-list-view__product {
  background: #fff;
  margin: 1.25rem;
  border: 1px solid #e2e1dd;

}
.ph-browse-list-view__product:first-child {
  margin-top: 0;
}
.ph-browse-list-view__left--img-container {
  margin: .5rem .325rem !important;
  /* min-width: 20px !important; */
  overflow: hidden;
  width: 12% !important;
}
/* .ph-browse-list-view__left--container {
  width: 88%;
} */
.support-wrapper .ph-browse-list-view__left {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.ph-browse-list-view__left {
  display: flex;
  overflow: hidden;
  position: relative;
  /* width: auto; */
}
.ph-browse-list-view__left--container {
  /* width: 88%; */
  margin: 0 .5rem;
}

.ph-browse-list-view__left--title {
  color: #000000;
  display: block;
  font-size: .875rem;
  /* font-weight: 400; */
  /* margin: .75rem 0 .625rem .5rem; */
  /* font-size: 1.3125rem; */
  margin: 1.125rem 0 .625rem .5rem;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  transition: .3s ease;
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}

.ph-browse-list-view__left--short-desc {
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  /* max-height: 78px; */
  overflow: hidden !important;
  padding: 0;
  text-overflow: ellipsis;
}

.ph-browse-list-view__right {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 22%;
  border-left: 1px solid #e2e1dd;
        justify-content: center;
        width: 27%;
}

.ph-browse-list-view__right .entry {
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
.ph-search-selected__query {
  /* margin: 16px; */
  vertical-align: top;
  display: inline-block;
  /* padding: 16px; */
}
hr{
  border-top: 2px solid #a09f9d;
}
/* .ph-search-content {
  padding: 0 1.5625rem;
} */

.srp-area{
  box-sizing: border-box;
  /* height: 174px;
  width: 1312px; */
  border: 1px solid #000000;
  border-radius: 4px;
}

.ph-browse-list-view__product {
    margin: 12px !important;
}

.hide-smallscreen{
  display: none;
}

/* .myparkersearch-tabs.nav-tabs .nav-item .nav-link:not(.myparkersearch-tabs.nav-tabs .nav-item .nav-link:active){
  background: none;
} */


.filter-list{
  margin-top: -25px;
    margin-bottom: 25px;
}
.filter-list span{
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.filter-list button{
  font-size: 13.3px;
  box-sizing: border-box;
  height: 33px;
  /* width: 89px; */
  background: none!important;
  border: 1px solid #000000!important;
  border-radius: 4px;
  margin-left: 1rem;
}
.support-email a{
  min-width: 175px!important;
  margin-top: 11.6px !important;
  position: absolute;
}

.filter-list button i{
  font-size: 16px!important;
  font-weight: 400!important;
    padding: 0 0 0 13px!important;
}

.support-list .ph-browse-list-view__right{
  border-left:none!important;
}
/* .support-list .support-email{
  margin-top: 0;
    margin-left: 0;
    padding-top: 8px;
  
} */
/* .support-email span{
  line-height: 1.8;
} */

/* .support-list .ph-browse-list-view__left{
  width: 60%;
} */

.showing-top-results h4{
  font-size: 16px;
  line-height: 20px;
  margin-left: 12px;
  padding-top: 16px;
    padding-bottom: 16px;
}

.ph-search-selected__query {
   margin-bottom: 19px!important; 
  margin-top: 19px!important;
  /* vertical-align: top; */
}

.table-responsive{
  width: 100%;
    overflow-y: scroll;
}

table.table tr th:first-child {
  width: 268px!important;
}
table.table tr th {
  width: 200px;
}

.srp-header{display: flex;}
.srp-header .showing-top-results{flex: 1;}
.srp-header select{
  width: 124px!important;
  border: 1px solid #424242;
  border-radius: 4px!important;
  margin-right: 16px;
  color: #000;
  /* margin-top: 14px; */
    height: 31px;
}
.sortby h4{
 
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 5px;
  margin-top: 0.4rem;
}
.sortby{display: flex;
  margin-top: 0.8rem;
}

.support-list .ph-browse-list-view__right {
  display: block;
  margin-top: 0;
  margin-right: 8px;
  width: 36%;
}

/* .ph-results-per-page{
  float: none;
} */

.sort-dropdown .btn-secondary{
  background: none !important;
    border-radius: 4px;
    border: 1px #000000 solid;
    margin-top: 9px;
    margin-right: 12px;
}
.sort-dropdown .btn{
  color: #000000!important;
}


.hide-largescreen{display: none;}

.createdate_publishdate{display: flex;}
.createdate_publishdate div{
  flex: 1;
  padding-top: 8px;
}

.description{
  
  text-align: left;
  /* margin-left: 0; */
  margin-top: 1rem;
  /* margin-left: 6rem!important; */

}
.description p, .description h4{font-size: 13.3px;}
.support-list .support-email {
  margin-top: 0;
  margin-left: 0;
  padding-top: 8px;
}

/* .link-label button{background: none;border-radius: 10px 10px 10px 10px;} */
.support-content-left p{
  font-size: 13.3px;
    letter-spacing: 0;
    line-height: 16.63px;
    font-weight: 500;
}

.support-content-left h4{
  font-size: 16px;
font-weight: 500;
letter-spacing: 0;
line-height: 20px;
}
.description div{
  font-size: 13.3px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17.6px;
}

.ph-browse-list-view__left--files {
  padding-bottom: 0;
  padding-top: 0;
}

.support-list .ph-browse-list-view__product{
min-height: 140px;
}


.description p {
  /* width: 283px; */
  /* white-space: nowrap; */
  /* text-wrap: nowrap; */
  overflow: hidden;
  /* word-wrap: break-word; */
  text-overflow: ellipsis;
}
.description p {
  font-size: 13.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.63px;
}


/* .support-list .ph-browse-list-view__left--container{
  width: 56%;
} */

/* .support-list .ph-browse-list-view__left--container {
  margin-top: 47px !important;
} */
.support-list .ph-browse-list-view__left--files div{
margin-left: -0.5rem;
}

.link-label button {
  background: none;
    border-radius: 40px;
    padding: 5px;
    min-width: 74px;
    margin-top: 5px;
    /* margin-left: 61px; */
    min-width: 64px;
}

.support-list .ph-browse-list-view__left--img-container {
   margin: 0 0.325rem 0 7px !important;
}
/* .support-list .ph-browse-list-view__left--container {
  margin-top: -56px !important;
} */

.sort-dropdown .btn-secondary{
  background: none !important;
    border-radius: 4px;
    border: 1px #000000 solid;
    margin-top: 9px;
    margin-right: 12px;
}
.sort-dropdown .btn{
  color: #000000!important;
}

/* NO Result Page box css */
.ph-results_noResultPage{
  margin-top: 1em;
  border: 1px solid;
  border-color: #E6E6E6;
  border-radius: .2em;
  padding: 1em;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
}

select:focus {
  background-image: url(../image/ph-arrow-down.png); }

.cross-ref table{
  width: 100%;
  border: 2px solid #ccc;
}

.ph-results-pagination li a{font-weight: 500;}

.cross-ref .ph-search-selected__query{margin-bottom: 0!important;}


.support-list .ph-browse-list-view__left--container {
  /* width: auto; */
  height: auto;
  /* margin-top: -30px !important; */
  margin-left: -1rem;
  /* margin-top: -56px !important; */
}

.support-list .ph-browse-list-view__right .entry {
  display: inline-block;
    position: relative;
    
  /* font-size: 1.125rem; */
  /* max-width: 100%; */
  /* padding: 0 1.25rem; */
}

.search-pagination-main{display: flex;}
/* .search-pagination-main div{flex:2} */
.search-pagination{margin-left: -3.4rem;}
.pres-per-page{    margin-left: 20rem;
  margin-top: 0.5rem;}

  .result-email-btn {
    position: absolute;
    bottom: 0;
    top: 6.2rem;
    right: 9.8rem;
}
.exact-match {
  box-sizing: border-box;
  height: 25.5px;
  width: 81.5px;
  border: 1.5px solid #FFB91D;
  border-radius: 4px;
  background: none !important;
  color: #000 !important;
  margin-left: 0.6rem !important;
}
.support-list .ph-browse-list-view__right .entry {
  font-size: 1.5rem;
  padding: 0 0;
}

.ph-results-pagination{
  margin-left: 1.8rem;
}
/* .ph-results-pagination .current .page-link1{
  margin-left: 2.5rem!important;
} */
.ph-results-pagination li.current a.page-link1 {
  border: 2px solid #ededed;
  margin-left: 2.5rem!important;
}

.cross-ref .table-responsive .table.table td a{
  color: rgb(0, 118, 204)!important;
}

.cross-ref .table-responsive table.table td i{
  color: #388e3c!important;
}
/* .sidepanel{display: none;} */
/* .filters_search{display: none;} */

.mob-all-filter{display: none;}
.support-content-right .ph-browse-list-view__right .entry{
  max-width: 100%!important;
}
/* .result-email-btn{display: none!important;} */

.search__submit{
  z-index: 99999;
}

.ph-browse-list-view__left--short-desc span{color: #000;}

/* Below css is only for localhost, don't push to any env */

custom-style.css
iframe{
  display: none !important;
}
.collapse {
  visibility: visible !important;
}

/* end */


/* email form css */

/* .float_left{float: left;} */
.error{color: red;}

#toTd, #fromTd{
  display: flex;}
  .fromEmailData, .toEmailData{
    position: relative;
    left: 1rem;
  }

  .support-content-left{color: #000;}
  .send-email-btn{
    width: 50%;
    padding-top: 0;
    padding-bottom: 0;
    color: white;
    background-color: black;
    border-radius: unset;
    border: 1px solid #000;
  }
 .cancel-email-btn{
  padding-top: 0;
    padding-bottom: 0;
    border-radius: unset;
    width: 50%;
    border: 1px solid #000;
    background: none;
    color: #000;
 }

 .success{color: #388e3c;}
 .link-label button{
  font-size: 12px;
 }
 .srch-btn .btn {

  background: #ffb91d;
}

.prod-table td{display: flex;}

.myself{
  border-top: 1px solid #000;
    border-right: none;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    width: 100px;
    text-align: center;
    border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  
  font-size: 12px;
}
.email-tabs .active{
  color: #fff!important;
  background-color: rgb(66, 66, 66);
  /* border-radius: 40px; */
}
.colleague{
  font-size: 12px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: none;
  width: 100px;
    text-align: center;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.table>:not(caption)>*>*{
  border-bottom-width: 0!important;
}
.productNameH3{
  font-size: 16.6px!important;
}
.required-info{font-style: italic;}
.createdate_publishdate {
 
  font-size: 13.3px;
}
.support-wrapper{
  width: 65%;
}
.createdate, .publishdate{
  font-size: 13.3px;
  font-weight: 500!important;
}

.float_left input[type=text]{
  border: 1px solid #302f2f;
}

.xreftab{display: none;}