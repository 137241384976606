.ph-flex-table__header div:nth-child(3) {
    flex: 1;
}

.ph-flex-table__row td, .ph-flex-table__header th {
    min-width: 120px;
}

.ph-flex-table__header{
    padding: 0.25rem;
}

.ph-flex-table__item {
    flex: 1;
}

.ph-flex-table__item {
    -ms-flex: 1;
    flex: 1;
}

.row-first-part{
    border-top: 1px solid #e2e1dd;
    border-left: 1px solid #e2e1dd;
    border-right: 1px solid #e2e1dd;
    margin-top: 5px;
}

.row-second-part{
    border-bottom: 1px solid #e2e1dd;
    border-left: 1px solid #e2e1dd;
    border-right: 1px solid #e2e1dd;
}

.token-display{
    display: flex;
    flex-direction: row;
    width:81.5%
}

.token-display span {
    padding: 1em 0.8em;
}

.ph_token_input{
    /* border-top:none !important; */
}


i.fa2{
    display: inline-block;
    padding: 1em 0.8em;
    cursor: pointer;
}

i.fa3{
    display: inline-block;
    padding-left: 0.8em !important;
    padding-right: 0.8em !important;
    cursor: pointer;
}

input[type="text"]{
    /* border-bottom:1px solid #8e8e8e;
    border-left:1px solid #8e8e8e;
    border-right:1px solid #8e8e8e; */
    margin-top:0px;
    margin-bottom:8px;
    padding:10px 25px 10px 10px !important;
}


@media screen and (max-width: 992px) {

    .ph-flex-table__header {
        flex-direction: row!important;
        min-width: 0px;
    }

    .ph-flex-table__row td, .ph-flex-table__header th {
        min-width: 0px !important;
    }

    .row-first-part {
        flex-direction: row!important;
    }
    .row-second-part {
        flex-direction: row!important;
    }

    .header-details {
        font-size: 14px;
        line-height: 36px;
        font-weight: 500;
        margin: 0 12px;
    }    
    
}


@media screen and (max-width: 470px) {

    .ph-modal__content {
        width: 80% !important;
        padding: 0 10px;
    }

    .ph-form-row__label {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0px;
    }
}

.text-c{
    text-align: center;
}

.ph-form-row__label{
    padding: 0;
}