body {
  font-family: 'Roboto';
  overflow-x: hidden; 
}

.header-details {
  color: #424242;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px;
  font-weight: 500;
}
.navigation-breadcrumb-browse {
  color: #575757;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 8px;
}
.padding-margin {
  margin-left: 5%;
  padding-top: 0.5em;
}
.line {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #E2E1DD;
}
.rectangle-header {
  height: 40px;
  background-color: #363636;
}
.sub-headers {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 10px;
  padding-left: 20px;
}


.user-details-container {
  /* width: 94%; */
  /* margin-left: 60px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item {
  box-sizing: border-box;
  flex: 25%;
  padding-top: 20px;
  margin-top: 5px;
  margin-right: 5px;
}
.item1 {
  box-sizing: border-box;
  flex: 25%;
  padding-top: 20px;
  margin-top: 5px;
  margin-left: 5px;
}
.mid-item {
  display: flex;
  flex-direction: column;  
  flex-basis: 30%;  
  padding-top: 23px;
  margin: 1px 5px;
}

.mid-item-a {
  flex: 1;
  box-sizing: border-box;
  margin-bottom: 20px;
  flex-basis: 30%;  
  border:1px solid #e7e7e7;
}

.mid-item-b {
  flex: 1;
  box-sizing: border-box;
  flex-basis: 30%;  
  border:1px solid #e7e7e7;
}

@media screen and (max-width: 728px) {
  .item {
    flex: 50%;
  }
}

@media screen and (max-width: 728px) {
  .mid-item {
    flex: 50%;
    margin-right: 4px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 728px) {
  .item1 {
    flex: 50%;
    margin-left: 0px;
    margin-right: 4px;
  }
}

@media screen and (max-width: 728px) {
  .user-details-container {
    flex-direction: column;
    margin-right: -5px;
  }
}

label {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

input[type=text] {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  display: block;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
}

.item-spacing {
  margin-bottom: 3rem;
}


div.profile-container {
  padding: 20px;
  border:1px solid #e7e7e7;
}
.send-a-reset-password {
  color: #4D4D4D;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.login-history-container {
  background-color: #f2f2f2;
  height:339px;
  overflow: auto;
  font-family: Roboto;
  font-size: 12px;
  border: 1px solid #e7e7e7;
}

.rowpt {
  padding: 7px 8px;
  line-height: 16pt;
}

div.login-history-container > div:nth-of-type(odd) {
  background-color: white;
}
.flex-container-assigned-loc {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-container-assigned-loc > div {
  padding-top: 20px;
  text-align: center;
}
.flex-child-buttons:first-child {
  margin-right: 20px;
} 
body {
  font-family: 'Roboto';
  font-size: 12px;
}

@media screen and (max-width: 728px) {
  .container-table {
    width:100%;
    /* overflow-x: scroll; */
  }
}
@media screen and (max-width: 600px) {
  .table-wrapper {
    overflow-x: scroll;
  }
}
.table-wrapper {
  border-radius: 1px;
}

table.table tr th,
table.table tr td {
  border-color: #e9e9e9;
  vertical-align: middle;
}

table.table tr th:first-child {
  width: 60px;
}

table.table tr th:last-child {
  width: 200px;
}

table.table-striped tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

table.table th i {
  font-size: 12px;
  margin: 0 5px;
  cursor: pointer;
}

table.table td:last-child i {
  opacity: 0.9;
  font-size: 12px;
  margin: 0 5px;
}

table.table td a {
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  outline: none !important;
}
table.table td a.done {
  width: 100px;
  color: #4D4D4D;
}
table.table td a.edit {
  width: 100px;
  color: #4D4D4D;
}

table.table td a.remove {
  color: #4D4D4D;
}

table.table td i {
  font-size: 12px;
}

.done-icon {
  color: #31B910; 
  font-weight: bold;
}

/* Custom checkbox */
.custom-checkbox {
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  margin: 5px 0 0 3px;
  z-index: 9;
}

.custom-checkbox label:before {
  width: 16px;
  height: 16px;
}

.custom-checkbox label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  background: white;
  border: 1px solid #bbb;
  border-radius: 1px;
  box-sizing: border-box;
  z-index: 2;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
  content: '';
  position: absolute;
  left: 6px;
  width: 6px;
  height: 11px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: inherit;
  z-index: 3;
  transform: rotateZ(45deg);
}


.custom-checkbox input[type="checkbox"]:disabled+label:before {
  color: #b8b8b8;
  cursor: auto;
  box-shadow: none;
  background: #ddd;
}
.button, a.button, button, [type=submit], [type=reset], [type=button] {
  -webkit-appearance: none;
  display: inline-block;
  border: 2px solid #ffb91d;
  border-radius: 4px;
  background: #ffb91d;
  color: #424242;
  font-weight: 500;
  font-family: "Roboto";
  font-size: 12px;
  text-transform: capitalize;
  padding: 1rem 1.25rem ;
  margin: 0 0 .5rem 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
   }

.button:hover, a.button:hover, button:hover, [type=submit]:hover, [type=reset]:hover, [type=button]:hover {
  border: 2px solid #e9a100;
  background: #e9a100;
  color: #424242;
  text-decoration: none; }

.button:focus, .button:active, a.button:focus, a.button:active, button:focus, button:active, [type=submit]:focus, [type=submit]:active, [type=reset]:focus, [type=reset]:active, [type=button]:focus, [type=button]:active {
  border: 2px solid #e9a100;
  background: #e9a100;
  color: #424242;
  text-decoration: none; }

.button::-moz-focus-inner, a.button::-moz-focus-inner, button::-moz-focus-inner, [type=submit]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=button]::-moz-focus-inner {
  border: 0;
  padding: 0; }

.accent-button,
a.accent-button {
  color: #424242;
  border: 2px solid #ffb91d;
  background: #ffffff; }
  .accent-button:focus, .accent-button:active,
  a.accent-button:focus,
  a.accent-button:active {
    color: #424242;
    border: 2px solid #ffb91d;
    background: #ffb91d; }

.muted-button,
a.muted-button {
  background: #f0f0ee;
  border: 1px solid #f2f2f0;
  color: #ccd2d7;}

.round-button,
a.round-button {
  border-radius: 40px; }

.square-button,
a.square-button {
  border-radius: 0; }

.full-button,
a.full-button {
  display: block;
  width: 100%; }

.small-button,
a.small-button {
  font-size: 0.75rem;
  font-weight: 500;
  padding: .25rem 1.25rem;
  margin: 0; }

.medium-button,
a.medium-button {
  font-size: .875rem;
  font-weight: 500;
  padding: .5rem 1.5rem;
  margin: 0; }





i.fa1 {
  display: inline-block;
  border-radius: 60px;
  border: 1px solid #FFB91D;
  padding: 0.5em 0.6em;
 
}



.hide
{
  display: none;
}

.tlrborder
{
  border-left: 1px solid #FFB91D !important; 
  border-top: 1px solid #FFB91D !important; 
  border-right: 1px solid #FFB91D !important; 
}


.lrborder
{
  border-left: 1px solid #FFB91D !important; 
  border-top: 1px solid #FFB91D !important; 
  border-right: 1px solid #FFB91D !important; 
}

.blrborder
{
  border-left: 1px solid #FFB91D !important; 
  border-bottom: 1px solid #FFB91D !important; 
  border-right: 1px solid #FFB91D !important; 
}


/* This is what we are focused on */
.table-wrapper{
  overflow-y: scroll;
  height: 150px;
}

.table-wrapper th{
    position: sticky;
    background-color: #FAF9F9;
    top: 0;
    font-weight: normal;
    height: 40px;
}

/* A bit more styling to make it look better */
.table-wrapper{
  background: white;
}

.table-wrapper table{
  border-collapse:separate;
  width: 100%;
}

.table-wrapper tr {
  border: 1px solid #E2E1DD;
}

.table-wrapper tr > th{
  background-color: #FAF9F9;
  padding-left:10px;
}
.table-wrapper tr>td
{
padding-left:10px;
}


.pagination a {
  color: #4d4d4d;
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  align-content: center;
  font-weight: 500;
}

.pagination-result span {
  color: #4d4d4d;
  float: right;
  padding: 8px 0px 8px 8px !important;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

/*.active {
  border: 1px solid #4d4d4d;
}*/

.all-users-tab {
  color: #9b9b9b;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  border-bottom: 2px solid #ffb91d;
  display: inline;
}

.active-tab {
  color: #9b9b9b;
  font-family: Roboto Medium;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 20px;
  display: inline;
}

.pending-tab {
  color: #9b9b9b;
  font-family: Roboto Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 20px;
  display: inline;
}

.menu-item {
  border-bottom: 1px solid #ccd2d7;
  margin: 25px 0px 20px 0px;
}
.outerpadding {
  margin-left: 77.5px;
  margin-right: 0px;
  padding-right: 77.5px;
}

@media screen and (max-width: 728px) {
  .outerpadding {
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
  }
}
.btn-import-user {
  border-radius: 60px 60px 60px 60px;
  background-color: #fff;
  border: 1px solid #ffb91d;
  color: #424242;
  min-width: 120px;
}

.btn-add-user {
  border-radius: 60px 60px 60px 60px;
  background-color: #ffb91d;
  border: 0px;
  color: #424242;
  min-width: 120px;
}

.search-icon::-webkit-input-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  width: 100% important;
  color: hsl(9, 40%, 60%);
}

.search-button-color {
  box-sizing: border-box;
  background-color: #ffb91d;
  color: #424242;
  text-align: center;
  align-content: center;
  width: 80px;
  height: 50px;
  padding: 12px;
  font-family: Roboto;
}

.btn-design-02 {
  font-size: 12px;
  background-color: #ffb104;
  border: 0px;
  border-radius: 0px;
}

.btn-design-02:hover,.btn-design-02:hover,.btn-design-02:focus {
  font-size: 12px;
  background-color: #ffb104;
  border: 0px;
  border-radius: 0px;
  border: none
}


.ms-choice,.ms-choice:hover,.my-choice:focus,.my-choice:active {
  background-color: white!important;
  background: white!important;
}

.content-space{
  height: 2rem;
}

.btn-alignment{
  text-align: right;
}

.text-font{
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
}

.main-content{
  margin-top:10px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px
}

.main-content-div {
  overflow-x:auto;
}

.table-content{
  width: 100%; 
  border-collapse: collapse;
  white-space: nowrap
}

.table-tr-content{
  border: 1px solid #E2E1DD;
  background-color: #FAF9F9;
  height: 40px;
}

.th-margin{
  margin-left: 10px;
}

.td-content-style{
  height: 6px;
  padding:0px;
}

.table-tr-style{
  border: 1px solid #E2E1DD;
  height: 65px;
}

.table-td-style{
  padding-left: 10px;
}

.img-style{
  margin-left: 5px;
}

.anchor-locations{
  text-decoration: underline;
  color:#0000ff;
}

.anchor-email{
  color:#0000ff;
}

.page-selection{
  border:none;
}

.nested-table-footer{
  padding-right: 10px;
}

.nested-third-td{
  text-align:right;
}

.footer-tr{
  border: 1px solid #E2E1DD;
  height: 40px;
}

.location-style{
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nested-tr-main{
  border: 1px solid #E2E1DD;
}

.nested-tr-main-div{
 padding-bottom: 20px;
 margin-left: 44px;
}

.search-input{
  margin-top: 0px !important;
}

.search-div{
  margin-top: 5px;
  border: 1px solid #000;
}

.dropdown-div{
  margin-top: 5px;
  /* border: 1px solid #000; */
  //height: 51px;
  height: 45px;
    width: 340px;
}

.ms-choice{
  height: 50px;
}

.th-border{
  border-left: 1px solid #E2E1DD;
  border-top: 1px solid #E2E1DD;
  border-bottom: 1px solid #E2E1DD;
}

.th-border-middle{
  border-top: 1px solid #E2E1DD;
  border-bottom: 1px solid #E2E1DD;
  margin-left: 0px;
}

.th-border-last{
  border-right: 1px solid #E2E1DD;
  border-top: 1px solid #E2E1DD;
  border-bottom: 1px solid #E2E1DD;
}

.user-heading{
  width: 90%; 
  margin:auto;
}

.btn-reset-div{
  text-align: left; 
  padding-left: 20px; 
  padding-top: 20px;
}

.btn-reset-inline{
  min-width:40%;
}

.btn-active{
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
}

.btn-active div{
   flex:1;
}

.label-style{
  font-weight: normal;
}

.btn-save{
  text-align:end; 
  margin-right: 20px; 
  margin-top: 34px;
}

.login-history{
  font-style: italic;
}

.user-anchor-style{
  font-size:14px;
}

.table-dropdown{
    box-sizing: border-box; 
    height: 23px; 
    border: 1px solid #CCD2D7;
}

.table-header-style{
  color: #4D4D4D; 
  font-weight: normal;
  border: none;
}

.table-checkbox-style{
  border: none;
}

.table-th-style{
  border: 1px solid #e2e1dd;
  background-color:#FAF9F9 ;
}

.user-btn-add{
  min-width:40%;
}

.assigned-style{
  margin-top: 30px;
}


