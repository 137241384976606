.parker-training-content {
  border: 50px solid #ffb91d;
  margin-top: 15px;
}
h1 {
  font-size: 1.875rem;
}
.ph-tops li:hover {
  border: solid 2px #ffb91d;
}
@media (max-width: 767px) {
  .parker-training-content {
    border: 20px solid #ffb91d;
    margin-top: 15px;
  }
}
.d-flex.justify-content-center {
  list-style-type: none !important;
}
