/* @media (min-width: 1301px) {
     .product-catalog-mainContainer {
        padding-left:0px !important;
        padding-right:0px !important;
    }
    .support-page .search-box-container {
        padding-left: 98px;
        padding-right: 98px;
    }
    }

    .search-input-box{
        width: 100%;
        border-radius: 0px;
        height: 46px;
        border: none;
  } */

  @media (min-width: 1301px) {
  .product-catalog-mainContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .support-page .search-box-container {
    padding-left: 98px;
    padding-right: 98px;
  }
}

.search-input-box {
  width: 100%;
  border-radius: 0px;
  height: 46px;
  border: none;
}

.container h1 {
  /* font-family: 'DINOT-Bold'; */
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 21px;
  color: #353535;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.pad0 {
  display: flex;
}
.support-block {
  background: #fff;
  border: 1px solid #dddddd;
  margin-right: 30px;
  width: 31%;
  height: 140px;
  padding: 0px !important;
}
.support-heading-block {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 63%;
  height: 100%;
  background: #e4e4e4;
  padding-left: 20px;
  cursor: pointer;
}
.support-heading-block:hover,
.support-heading-block:active,
.support-heading-block:focus {
  background-color: #fdd880;
  text-decoration: none;
}
.marT30 {
  margin-top: 30px;
}

.left {
  float: left;
}
.right {
  float: right;
}

@media (min-width: 1301px) {
  .acme-support-assets-block {
    padding-left: 98px !important;
    padding-right: 98px !important;
  }
}

/* new css */
